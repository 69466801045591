import * as React from 'react';
import {Route, Switch} from 'react-router-dom';

import loadable from '@loadable/component';

import {loadableDelay, params} from '@common/react/loadable/loadableSettings';
import PrivateRoute from '@common/react/components/Pages/PrivateRoute';

import Layout from './components/Layout';

/*--------------Admin---------------*/

/*------------Admin end-------------*/

const Home = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "HomePage" */ './components/Pages/Home/Home')), params);
const Login = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "LoginPage" */ './components/Pages/Login/Login')), params);
const Recover = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "RecoverPage" */ './components/Pages/Recover/Recover')), params);
const Confirm = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ConfirmPage" */ './components/Pages/Confirm/Confirm')), params);
const Register = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "RegisterPage" */ './components/Pages/Register/Register')), params);
const Profile = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "RegisterPage" */ './components/Pages/Profile/Profile')), params);
const EditProfile = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "RegisterPage" */ './components/Pages/Profile/EditProfile')), params);
const About = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "AboutPage" */ './components/Pages/About/About')), params);
const Listing = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ListingPage" */ './components/Pages/Listing/Listing')), params);
const Results = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ResultsPage" */ './components/Pages/Results/Results')), params);
const Disclaimer = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "DisclaimerPage" */ './components/Pages/Disclaimer/Disclaimer')), params);
const AccessibilityPolicy = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "AccessibilityPage" */ './components/Pages/AccessibilityPolicy/AccessibilityPolicy')), params);
const PrivacyPolicy = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "PolicyPage" */ './components/Pages/PrivacyPolicy/PrivacyPolicy')), params);
const Directory = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "DirectoryPage" */ './components/Pages/Directory/Directory')), params);
const TermsAndConditions = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "TermsAndConditionsPage" */ './components/Pages/TermsAndConditions/TermsAndConditions')), params);

export const routes = <Layout>
	<Switch>
		<Route exact path="/" component={Home} />
		<Route path="/login" component={Login} />
		<Route path="/recover" component={Recover} />
		<Route path="/confirm" component={Confirm} />
		<Route path="/create-account" component={Register} />
		<Route path="/about" component={About} />
		<Route path="/using-vetangel" component={Directory} />
		<Route path="/disclaimer" component={Disclaimer} />
		<Route path="/accessibility" component={AccessibilityPolicy} />
		<Route path="/privacy-policy" component={PrivacyPolicy} />
		<Route path="/terms-and-conditions" component={TermsAndConditions} />
		<Route exact path="/results/:zip" component={Results} />
		<PrivateRoute path="/profile/edit-profile" component={EditProfile} />
		<PrivateRoute path="/profile/listing/:stepURL" component={Listing} />
		<PrivateRoute path="/profile" component={Profile} />
	</Switch>
</Layout>;
