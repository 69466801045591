import React from 'react';

import { Link, NavLink } from 'react-router-dom';

import '@app/scss/components/cookies-banner.scss';

const CookiesBanner = () => {
	const [visible, setVisible] = React.useState<boolean>(false);

	React.useEffect(() => {
		const dataStorage = localStorage?.getItem('acceptWindow');

		if (!!dataStorage) {
			const data = JSON.parse(dataStorage);
			if (data.timestamp > Date.now()) {
				setVisible(data.acceptWindowVisible);
			} else {
				localStorage.removeItem('acceptWindow');
			}
		} else {
			setVisible(true);
		}
	}, []);

	return <div className={`cookies-banner ${visible ? '' : 'hide'}`}><div className="banner"><div className="container">
		<p className="cookies-banner__text">
			We use cookies to provide and improve our services. By selecting Accept or continuing to use our site, you consent to their use.<br />
			To learn more, read our <Link to={'/privacy-policy'} target={'_blank'}>Privacy Policy</Link>.
		</p>
		<button 
			className="btn btn-teal-border btn-cookies-banner"
			onClick={() => {
				const date = Date.now() + 604800000000;
				const data = {
					acceptWindowVisible: false,
					timestamp: `${date}`
				};

				localStorage.setItem('acceptWindow', JSON.stringify(data));
				setVisible(false);
			}}
		>
			Accept
		</button>
	</div></div></div>;
};

export default CookiesBanner;