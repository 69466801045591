import * as React from 'react';

import { Link, NavLink } from 'react-router-dom';

const year = new Date().getFullYear();

const Footer: React.FC<{}> = () => {
	return <footer className="site-footer">
		<div className="container">
			<ul className="menu-component">
				<li className="menu-component__item"><Link to={'/using-vetangel'} target={'_blank'}>Using VetAngel</Link></li>
				<li className="menu-component__item"><Link to={'/disclaimer'} target={'_blank'}>DISCLAIMER</Link></li>
				<li className="menu-component__item"><Link to={'/privacy-policy'} target={'_blank'}>Privacy Policy</Link></li>
				<li className="menu-component__item"><Link to={'/terms-and-conditions'} target={'_blank'}>Terms and Conditions</Link></li>
				<li className="menu-component__item"><Link to={'/accessibility'} target={'_blank'}>Accessibility Policy</Link></li>
			</ul>
			<p className="site-footer__copyright">
				© {year} VetAngel. All rights reserved.
			</p>
		</div>
	</footer>;
};

export default Footer;