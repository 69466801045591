import * as React from 'react';

import {Redirect, useHistory, Link} from 'react-router-dom';

import Search, { SearchProps } from 'antd/lib/input/Search';
import {Input} from 'antd';

interface ComponentProps extends SearchProps {
	classSearchBtn?: string;
	setSearchActive?: any;
	setSearch?: (value: string) => void;
	setValue?: (string) => void;
	handleSearch: () => void;
	customButtonText?: string;
	setValid?: any;
	isDisable?: boolean;
}

const SearchField = React.forwardRef<typeof Search, ComponentProps>((props, ref) => {
	const [redirectUrl, setRedirect] = React.useState('');
	const [isValidFormat, setIsValidFormat] = React.useState<boolean>(true);
	const [strValue, setStrValue] = React.useState(props.value);

	const inputRef = React.useRef() as React.RefObject<Input>;

	const enterButton = (
		<button
			type="button"
			className={`btn ${props.classSearchBtn ? props.classSearchBtn : ''}`}
			disabled={props.isDisable}
		>
			{props.customButtonText ?? 'search'}
		</button>
	);

	const isValidZip = (value: string) => {
		const code = value.toUpperCase();
		
		return code.search(/^[0-9]{5}$/) === 0 || code.search(/^[A-Z][0-9][A-Z]\s{0,1}[0-9][A-Z][0-9]$/) === 0 || code.search(/^[A-Z][0-9][A-Z]$/) === 0;
	};

	const handleOnSearch = (value: string) => {
		if (isValidZip(value)) {
			setIsValidFormat(true);
			props.setValid(true);
			props.handleSearch();
		} else {
			setIsValidFormat(false);
			props.setValid(false);
		}
	};

	const handleChange = (e) => {
		const value = e.currentTarget.value;

		setStrValue(value);

		isValidZip(value) && props.setValue && props.setValue(value);
	};

	return (
		<React.Fragment>
			{redirectUrl &&
				<Redirect to={redirectUrl}/>
			}

			<Search
				// @ts-ignore
				ref={inputRef}
				placeholder={props.placeholder || 'Search'}
				className={'search-field'}
				onSearch={(value) => {
					handleOnSearch(value);
				}}
				onChange={handleChange}
				enterButton={enterButton}
				value={strValue}
			/>
			{!isValidFormat && <p className="not-validation-format">Enter valid US zip or CA postal code</p>}
		</React.Fragment>
	);
});

export default SearchField;