import * as React from 'react';

import { Modal } from 'antd';

interface CustomModalProps {
	modalVisible: boolean;
	setModalVisible: (e: boolean) => void;
	onCancel?: () => void;
	img?: string;
	title?: string;
	text?: string | JSX.Element;
	textButton?: string;
	buttonClass?: string;
	addClass?: string;
	defaultBtn?: boolean;
	closable?: boolean;
	maskClosable?: boolean;
	width?: number | string;
	warningMessage?: string | JSX.Element;
}

const CustomModal: React.FC<CustomModalProps> = (props) => {
	const {
		modalVisible,
		setModalVisible,
		onCancel = () => setModalVisible(false),
		title = 'Service unavailable. Please try again later',
		textButton = 'Close',
		buttonClass = 'btn-teal-fill',
		img,
		text,
		children,
		addClass = '',
		defaultBtn = true,
		maskClosable = true,
		closable = false,
		width,
		warningMessage
	} = props;

	return (
		<Modal
			wrapClassName={`modal-wrap modal__custom-wrap ${addClass}`}
			visible={modalVisible}
			onOk={() => setModalVisible(false)}
			onCancel={onCancel}
			centered
			closable={closable}
			maskClosable={maskClosable}
			width={width}
		>
			<div className="modal__header">
				{img &&
					<img src={img} alt="img" className="modal__img"/>
				}
				<span className="modal__title" dangerouslySetInnerHTML={{
					__html: title
				}}/>
			</div>
			{warningMessage &&
				<div className="modal__warning">
					{warningMessage}
				</div>
			}
			<div className="modal__body">
				{text &&
					<p className="modal__text">
						{props.text}
					</p>
				}
				{children}
				{defaultBtn &&
					<button className={`btn ${buttonClass} modal__btn`} onClick={onCancel}>{textButton}</button>
				}
			</div>
		</Modal>
	);
};

export default CustomModal;
