import { Modal } from 'antd';
import * as React from 'react';
import {Link, NavLink, RouteComponentProps} from 'react-router-dom';
import {connect} from 'react-redux';

import {getUserName} from '@common/react/utils/utils';
import Logout from '@common/react/components/UI/Logout/Logout';

import * as LoginState from '@app/store/Login';
import {ApplicationState} from '@app/store';

interface ModalProps {
	modalVisible: boolean;
	setModalVisible: (e: boolean) => void;
}

type MobileMenuModalProps = LoginState.LoginState & ModalProps;
const userSvg = require('@app/images/user-teal.svg');
const logOutSvg = require('@app/images/logout-teal.svg');

const MobileMenuModal: React.FC<MobileMenuModalProps> = (props) => {
	const {user, modalVisible, setModalVisible} = props;

	return (
		<Modal
			wrapClassName="modal-wrap menu-mobile-modal"
			visible={modalVisible}
			onOk={() => setModalVisible(false)}
			onCancel={() => setModalVisible(false)}
		>
			<div className="mobile-menu">
				<div className="container">
					<div className="mobile-menu-body">
						<Link to="/" className="mobile-menu__item" onClick={() => setModalVisible(false)}>Home</Link>
						<Link to="/" className="mobile-menu__item" onClick={() => setModalVisible(false)}>Find a Vet</Link>
						{user ?
							<>
								<NavLink to="/profile" className="mobile-menu__item" onClick={() => setModalVisible(false)}>
									<img src={userSvg} alt="user"/>
									{user.userName ? user.userName : user.email}</NavLink>
								<Logout render={logout =>  <a onClick={(event) => {
									logout(event);
									setModalVisible(false);
								}} className="logout-component mobile-menu__item">Log out <img src={logOutSvg} alt="out"/></a>} />
							</>
							:
							<>
								<Link to="/login" className="mobile-menu__item" onClick={() => setModalVisible(false)}>Provider login</Link>
								<Link to="/create-account" className="mobile-menu__item" onClick={() => setModalVisible(false)}>Create account</Link>
							</>
						}
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default connect<LoginState.LoginState, {}, any, ApplicationState>(
	(state: ApplicationState) => state.login,
)(MobileMenuModal);