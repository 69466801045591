import * as React from 'react';
import {  RouteComponentProps, withRouter } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.css';

import '@app/scss/style.scss';

import Header from './UI/Header/Header';
import Footer from './UI/Footer/Footer';
import CookiesBanner from './UI/Banner/CookiesBanner';

type PageProps = RouteComponentProps<{}>;

const Layout: React.FC<PageProps> = (props) => {
	const [showSearchBar, setShowSearchBar] = React.useState(false);

	React.useEffect(() => {
		const ignorePaths: RegExp[] =  [
			/^\/$/i,
			/^\/login\/?$/i
		];

		for (const path of ignorePaths) {
			if (path.test(props.location.pathname)) {
				setShowSearchBar(false);
				return;
			}
		}

		setShowSearchBar(true);
	}, [props.location]);

	return <>
		<div className="app-container">
			<Header showSearchBar={showSearchBar} menuItems={[]}/>
			<div className="content">{props.children}</div>
			<Footer/>
		</div>
		<CookiesBanner />
	</>;
};

export default withRouter(Layout) as any;