import * as React from 'react';
import {useHistory} from 'react-router-dom';

import {Rows} from '@common/typescript/objects/List';

import SearchField from '@app/components/Forms/SearchField/SearchField';
import CustomModal from '@app/components/UI/CustomModal/CustomModal';
import {request} from '@app/components/Api';

import '@app/scss/components/find-vet.scss';

interface FindVetProps {
	customButtonText?: string;
	classSearchBtn?: string;
	setValid?: any;
}

enum ModalType {
	NoResults,
	Error
}

const FindVet: React.FC<FindVetProps> = ({ customButtonText, classSearchBtn, setValid = true}) => {
	const [inputValue, setInputValue] = React.useState('');
	const [shouldRedirectToResults, setShouldRedirectToResults] = React.useState(false);
	const [modalVisible, setModalVisible] = React.useState<boolean>(false);
	const [modalType, setModalType] = React.useState<ModalType>(ModalType.NoResults);
	const history = useHistory();
	const [isDisable, setDisable] = React.useState(false);

	const handle = () => {
		setDisable(true);
		request<Rows<any>>('listingListByZip', {
			text: inputValue.replace(' ', ''),
			isSaving: true
		})
			.then((response) => {
				if (response.count > 0) {
					history.push(`/results/${inputValue}`);
				} else {
					setModalType(ModalType.NoResults);
					setModalVisible(true);
				}
			})
			.catch(() => {
				setModalType(ModalType.Error);
				setModalVisible(true);
			})
			.finally(() => {
				setDisable(false);
			});
	};

	return (
		<>
			<SearchField
				placeholder="Enter ZIP or Postal Code"
				value={inputValue || ''}
				setValue={setInputValue}
				classSearchBtn={classSearchBtn}
				handleSearch={handle}
				customButtonText={customButtonText}
				setValid={setValid}
				isDisable={isDisable}
			/>

			<CustomModal
				modalVisible={modalVisible}
				setModalVisible={setModalVisible}
				title={modalType === ModalType.NoResults ? 'No providers found<br> servicing this location.' : 'Service unavailable<br> Please try again later.'}
				img={require('@app/images/paws.svg')}
				addClass={'message-modal'}
				text={<>
					If this is an emergency, contact your<br/> local veterinarian&nbsp;or&nbsp;emergency clinic.
				</>}
			/>
		</>
	);
};

export default FindVet;
