import {ReducersMapObject} from 'redux';

import * as Items from '@common/react/store/ItemList';
import * as Item from '@common/react/store/Item';
import {BaseApplicationState, BaseAppThunkAction, baseReducers} from '@common/react/store';

import {User} from '@app/objects/User';
import {Page} from '@app/objects/Page';
import {Inquiry} from '@app/objects/Inquiry';

// The top-level state object
export interface ApplicationState extends BaseApplicationState<User> {
	inquiries: Items.ItemsState<Inquiry>;

	user: Item.ItemState<User>;
	users: Items.ItemsState<User>;
	page: Item.ItemState<Page>;
	pages: Items.ItemsState<Page>;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers: ReducersMapObject = {
	...baseReducers,

	inquiries: Items.getReducer<Inquiry>('inquiries'),
	user: Item.getReducer<User>('user'),
	users: Items.getReducer<User>('users'),
	page: Item.getReducer<Page>('page'),
	pages: Items.getReducer<Page>('pages'),
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> extends BaseAppThunkAction<TAction, User, ApplicationState> {}
