import * as React from 'react';
import { connect } from 'react-redux';

import {LoginActionCreators as BaseLoginActionCreators, LoginState as BaseLoginState, getActionCreators} from '@common/react/store/Login';
import {BaseUser} from '@common/react/objects/BaseUser';
import { BaseApplicationState } from '@common/react/store';

interface OwnProps {
	render?: (logout: (event: React.MouseEvent) => void) => JSX.Element;
	clearState?: boolean;
}

type ActionCreators = BaseLoginActionCreators<BaseUser, BaseApplicationState<BaseUser>>;

type LoginState = BaseLoginState<BaseUser>;

type LogoutProps =
	LoginState
	& ActionCreators
	& OwnProps;

class Logout extends React.Component<LogoutProps, {}> {
	constructor(props: LogoutProps) {
		super(props);

		this.logout = this.logout.bind(this);
	}

	shouldComponentUpdate(nextProps: LogoutProps, nextState: Readonly<{}>, nextContext: any): boolean {
		return nextProps.user !== this.props.user;
	}

	logout(event: React.MouseEvent) {
		event.preventDefault();
		this.props.logoff(this.props.clearState);
	}

	public render() {
		const {render} = this.props;
		return render ? render(this.logout) : <a onClick={this.logout} className="logout-component">Logout</a>;
	}
}

export default connect<LoginState, ActionCreators, OwnProps, BaseApplicationState<BaseUser>>(
	(state: BaseApplicationState<BaseUser>) => state.login,
	getActionCreators() as any
)(Logout);