import * as React from 'react';

import '@app/scss/mobileMenu.scss';
import MobileMenuModal from '@app/components/UI/MobileMenuModal/MobileMenuModal';

const MobileMenu: React.FC = (props) => {
	const [modalVisible, setModalVisible] = React.useState<boolean>(false);
	const openModal = () => {
		setModalVisible(true);
	};

	return (
		<>
			<button type="button" onClick={() => openModal()} className="btn btn-icon mobile-menu-button">
				<img className="btn-icon-image" src={require('@app/images/menu.svg')} alt="search" width="22" />
			</button>
			
			<MobileMenuModal
				modalVisible={modalVisible}
				setModalVisible = {setModalVisible}
			/>
		</>
	);
};

export default React.memo(MobileMenu);