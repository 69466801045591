import * as React from 'react';
import {connect} from 'react-redux';
import {NavLink, Link, RouteComponentProps, withRouter} from 'react-router-dom';

import {MenuItem} from '@common/react/objects/MenuItem';
import Logout from '@common/react/components/UI/Logout/Logout';

import * as LoginState from '@app/store/Login';
import {ApplicationState} from '@app/store';
import {Page} from '@app/objects/Page';
import listenerForBrowser from '@app/components/Pages/listenerForBrowser';
import MobileMenu from '@app/components/UI/Header/MobileMenu';
import FindVet from '@app/components/UI/FindVet/FindVet';

interface PassedProps {
	menuItems: Array<Page | MenuItem>;
	showSearchBar: boolean;
}

type HeaderProps = LoginState.LoginState & PassedProps & RouteComponentProps<{}>;

interface HeaderState {
	isMobile: boolean;
	isValid: boolean;
}

const logoSvg = require('@app/images/logo.svg');
const userSvg = require('@app/images/user-teal.svg');
const logOutSvg = require('@app/images/logout-teal.svg');

class Header extends React.Component<HeaderProps, HeaderState> {
	constructor(props: HeaderProps) {
		super(props);

		this.state = {
			isMobile: false,
			isValid: true,
		};
	}

	componentDidMount() {
		const mql = window.matchMedia('screen and (max-width: 768px)');

		const listenerFunc = () => {
			if (mql.matches) {
				this.setState({isMobile: true});
			} else {
				this.setState({isMobile: false});
			}
		};
		listenerFunc();
		listenerForBrowser(mql, listenerFunc);
	}

	render() {
		const {user} = this.props;
		const currentRoute = this.props.location.pathname;

		const setIsValid = (value) => {
			this.setState({isValid: value});
		};

		return <header className="site-header">
			<nav className="navbar navbar-default navbar-fixed-top">
				<div className="container">
					<div className="navbar-wrapper">
						<NavLink to="/">
							<img className="site-header-logo" src={logoSvg} alt="VetAngel | In-Home Pet Hospice & Euthanasia Directory" />
						</NavLink>
						{!user && this.props.showSearchBar &&
							<div className="site-header-search">
								<FindVet customButtonText="Find a Vet" classSearchBtn={'find-vet__btn'} setValid={setIsValid}/>
							</div>
						}
						<div className="site-header-buttons">
							{!this.state.isMobile ? (user
								? <>
									<NavLink to="/profile" className="site-header__link">
										<img src={userSvg} alt="user"/>
										{user.userName}
									</NavLink>
									<Logout render={logout => <a onClick={logout} className="logout-component">Logout <img src={logOutSvg} alt="out"/></a>}/>
								</>
								:
								<>
									<NavLink to="/login" className="site-header__link-alternative">
										<img src={userSvg} alt="user"/>
										Provider Account
									</NavLink>
								</>)
								:
								<MobileMenu/>
							}
						</div>
					</div>
				</div>
			</nav>
		</header>;
	}
}

export default withRouter(connect<LoginState.LoginState, {}, any, ApplicationState>(
	(state: ApplicationState) => state.login,
)(Header));